@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  @include h1;
  text-align: center;
  margin-bottom: 8px;

  @include sm {
    margin-bottom: 16px;
  }
}

.mainContainer {
  @include verticalGap(32px);

  .subtitle {
    @include body-text('md');
    text-align: center;
    padding: 0 10px;

    @include sm {
      @include body-text('lg');
    }
  }

  .card {
    @include verticalGap(32px);
    border: 1px solid $black10;
    border-radius: 12px;
    box-shadow: $deepBoxShadow;
    padding: 24px;
    align-items: center;
    justify-content: center;

    .image {
      max-width: 124px;
    }

    .testimonyContainer {
      position: relative;
      @include verticalGap(16px);

      .testimony {
        @include body-text(lg);
        color: $black60;
      }

      .personalInfoContainer {
        position: relative;
        text-align: center;

        .name {
          @include body-text(lg, $semiBoldFontWeight);
        }

        .credentials {
          @include body-text(md, $regularFontWeight);
          color: $black60;
        }

        .closeQuotesIcon {
          position: absolute;
          top: -20px;
          right: 0;
        }
      }

      .openQuotesIcon {
        position: absolute;
        top: -25px;
        left: 0;
      }
    }
  }

  .cardWithLessGap {
    gap: 8px;
    padding: 0 24px 24px;
  }
}

.buttonsContainer {
  @include verticalGap(20px);
}

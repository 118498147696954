@import 'src/styles/mixins';
@import 'src/styles/variables';

.mainContainer {
  @include verticalGap(4px);

  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  @include sm {
    gap: 40px;
    width: 720px;
    margin-bottom: 20px;
    justify-content: center;
  }
}

.stepContainer {
  @include verticalGap(4px);
  cursor: pointer;

  span {
    font-size: 10px;
    font-weight: $mediumFontWeight;
    line-height: 16px;
    color: $black60;
    padding: 0 8px;

    @include sm {
      font-size: 12px;
    }
  }
}

.disabled {
  cursor: default;
}

.activeStepText {
  span {
    color: $black100;
  }
}

.stepIndicator {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: $black10;
}

.activeStepBar {
  background-color: $black60;
}
